import React, { useState } from 'react';
import Select from "react-select";
import timezones from './timezones';
import TimePicker from 'rc-time-picker';
import './rc-time-picker.css';
import moment from 'moment';

const format = 'h:mm a';

const DailyMotivationEmailForm = () => {
  const [selectedTimezone, setSelectedTimezone] = useState({ label: Intl.DateTimeFormat().resolvedOptions().timeZone.toString(), value: Intl.DateTimeFormat().resolvedOptions().timeZone.toString() });

  const [selectedTime, setNow] = useState(moment().hour(8).minute(0));

  return (
    <div className="my-5" style={{ maxWidth: "600px", marginLeft: "auto", marginRight: "auto" }}>
      <form>
        {/* <div class="form-group">
          <label htmlFor="name">Name</label>
          <input type="text" class="form-control" id="name" />
          <small id="emailHelp" class="form-text text-muted">What the reader will be referred to as.</small>
        </div> */}
        <div className="form-group">
          <label htmlFor="email">Email address</label>
          <input type="email" className="form-control" id="email" aria-describedby="emailHelp" />
          <small id="emailHelp" className="form-text text-muted">I will never share your email with anyone.</small>
        </div>

        <div className="form-group md-form md-outline input-with-post-icon timepicker" twelvehour="true">
          <label htmlFor="twelve-hour-clock">Time</label>
          <TimePicker
            showSecond={false}
            defaultValue={selectedTime}
            className="form-control"
            onChange={(x) => setNow(x)}
            format={format}
            use12Hours
          />
          <i className="fas fa-envelope input-prefix"></i>
        </div>

        <div className="form-group md-form md-outline input-with-post-icon timepicker" twelvehour="true">
          <label htmlFor="twelve-hour-clock">Timezone</label>
          <Select
            onChange={(e) => setSelectedTimezone(e)}
            // defaultInputValue={Intl.DateTimeFormat().resolvedOptions().timeZone.toString()}
            options={timezones}
            value={selectedTimezone}
          />
          <i className="fas fa-envelope input-prefix"></i>
        </div>
        {/* <div className="form-group form-check">
          <input type="checkbox" className="form-check-input" id="agree1" />
          <label className="form-check-label" htmlFor="agree1">I agree to receive 1 motivational email a day until I unsubscribe.</label>
        </div> */}
        <button type="submit" className="btn btn-primary">Get daily email ({selectedTime.format("H:mma")} in {selectedTimezone.label})</button>
      </form>
    </div>
  );
};

export default DailyMotivationEmailForm;
